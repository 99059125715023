<template>
  <div class="VariantSelector mb-16">
    <div
      v-for="(parametric, index) in parametrics"
      :key="'variant-parametric-' + parametric.code"
    >
      <div
        v-if="parametric.values.length"
        class="mobOnly:px-12 type-sm-medium mb-12 uppercase"
      >
        <div v-if="!isQuickBuy" class="mb-4">{{ parametric.name }}</div>
        <div class="flex flex-wrap">
          <div
            v-for="value in parametric.values.filter(v => v)"
            :key="'variant-parametric-value' + value.code"
            class="mr-8 mb-8 last-of-type:mr-0"
          >
            <button
              class="btn btn&#45;&#45;secondary"
              :class="[ getOptionClasses(parametric, value), {
                '!normal-case': value.code?.toLowerCase() === NorceParametricCodes.Volume.toLowerCase(),
                'selected': isSelected(parametric, value),
                'primary': index === 0,
              }]"
              @click="selectParametric(parametric, value)"
            >
              {{ value.uom ? value.name + ' ' + value.uom : value.name }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ProductModel } from '~/models/product';
import { ProductVariantModel } from '~/models/productVariant';
import { NorceParametricCodes } from '~/constants/norceCodes';
import { useUiStore } from '~/store/ui';
import { ParametricData, ProductVariantParametrics, ProductVariantParametricValue } from '~/constants/types/algolia';

const uiStore = useUiStore();

interface Props {
  productItem: ProductModel,
  activeVariant: ProductVariantModel,
  isQuickBuy?: boolean,
}

const props = withDefaults(defineProps<Props>(), {
  isQuickBuy: false,
});

type SelectedType = { parametric: string, value: string, type: 'list' | 'multiple' | 'integer' | 'text' | 'decimal' }[]

const getParametricDataValue = (parametricData: ParametricData): string => {
  switch (parametricData.type) {
    case 'list':
      return parametricData.listValue?.code ?? '';
    case 'multiple':
      if (parametricData.multipleValues?.length) {
        return parametricData.multipleValues[0]?.code;
      }
      return '';
    default:
      return parametricData.value +'';
  }
};

const selected = computed(()=> {
  const output = [] as SelectedType;
  props.activeVariant.definingParametrics.forEach((vp)=> {
    const selectedOption = getParametricDataValue(vp);
    output.push({
      parametric: vp.code,
      value: selectedOption,
      type: vp.type,
    });
  });
  return output;
});

const isSelected = (parametric: ProductVariantParametrics, value: ProductVariantParametricValue) => {
  const thisSelected = selected.value.find((f) => f.parametric === parametric.code );
  if (thisSelected) {
    if (thisSelected.type === 'multiple' || thisSelected.type === 'list') {
      return  thisSelected.value === value.code;
    } else {
      return thisSelected.value === value.name;
    }
  }
  return false;
};

const emit = defineEmits<{
  (e: 'updateActiveVariant', obj: ProductVariantModel): void;
}>();

const parametrics = computed(() => {
  return props.productItem.allVariantDefiningParametrics.filter((p) => p.code !== NorceParametricCodes.SalesRepNo).map((p) => {
    try {
      p.values = p.values.sort((a,b) => parseInt(a?.name) - parseInt(b?.name));
    } catch (e) {
      console.warn('Couldn\'t sort parametric values', e);
    }
    return p;
  });
});

const getOptionClasses = (
  parametricCode: ProductVariantParametrics,
  valueCode: ProductVariantParametricValue
): string => {
  const getVariant = getVariantFromSelected(
    parametricCode,
    valueCode,
    false,
    true
  );
  if (getVariant) {
    // Product is not coming back
    if (getVariant.productHasExpired) {
      return '';
    } else if (!getVariant.canAddToCart) {
      // temporary gone, but can signup for notification
      return 'notAvaliable';
    }
  } else {
    // Product doesn't exist
    return 'nonExisting pointer-events-none';
  }
  return '';
};

const selectParametric = (
  parametricCode: ProductVariantParametrics,
  valueCode: ProductVariantParametricValue
) => {
  const getVariant = getVariantFromSelected(parametricCode, valueCode);
  if (getVariant) {
    emit('updateActiveVariant', getVariant);
  }
};

const getVariantFromSelected = (
  parametricCode: ProductVariantParametrics,
  valueCode: ProductVariantParametricValue,
  findAlternative = true,
  showUserWarnings = true
): ProductVariantModel | null => {
  const thisSelected = JSON.parse(JSON.stringify(selected.value)) as SelectedType;
  const changeIndex = thisSelected.findIndex((f) => {
    return f.parametric === parametricCode.code;
  });
  if (changeIndex > -1) {
    if (thisSelected[changeIndex].type === 'list' || thisSelected[changeIndex].type === 'multiple') {
      thisSelected[changeIndex].value = valueCode.code;
    } else {
      thisSelected[changeIndex].value = valueCode.name;
    }

    // Check if the new combo exists
    const existsIndex = props.productItem.variants.findIndex((variant) => {
      let result = true;
      thisSelected.forEach((ts) => {
        const findParametric = variant.definingParametrics.find((vp) => vp.code === ts.parametric);

        if (!findParametric) {
          result =  false;
        } else {
          const findParametricValue = getParametricDataValue(findParametric);
          if (findParametricValue !== ts.value) {
            result = false;
          }
        }
      });
      return result;
    });

    if (existsIndex > -1) {
      return props.productItem.variants[existsIndex];
      //emit('updateActiveVariant', props.productItem.variants[existsIndex]);
    } else if (findAlternative) {
      // changing to a variant that exists
      const fallbackIndex = props.productItem.variants.findIndex((variant) => {
        const findParametric = variant.definingParametrics.find((vp) => vp.code === parametricCode.code);
        if (!findParametric) {
          return false;
        } else {
          const findParametricValue = findParametric.multipleValues?.length ? findParametric.multipleValues[0] : findParametric.listValue;
          return findParametricValue?.code === valueCode.code;
        }
      });

      if (fallbackIndex > -1) {
        console.log('Used fallback variant');
        return props.productItem.variants[fallbackIndex];
        //emit('updateActiveVariant', props.productItem.variants[fallbackIndex]);
      } else {
        console.warn('couldn\'t find any matching variant');
        if (showUserWarnings) {uiStore.setTemporaryError('couldn\'t find any matching variant');}
      }

    }

  } else {
    if (showUserWarnings) {uiStore.setTemporaryError('Problem finding selected variant');}
  }
  return null;

};

</script>

<style lang="postcss" scoped>

.expired {
  @apply line-through opacity-40 pointer-events-none;
}
.nonExisting {
  @apply opacity-30 cursor-not-allowed;
}
</style>
